import { Injectable } from '@angular/core';
import * as ChartJS from 'chart.js';

import { range } from 'rxjs';

import * as moment from 'moment';

import { DataChartADRRevPar, DataChartArrivalOvernight, DataChartOccupation, DataChartPropertyTypeDonut } from '../../main/main-panel/interfaces';

@Injectable()
export class ChartjsService {
  legend = {};
  transitions = {};
  scalesCurrency = {};
  scalesBeginZero = {};

  colors = [
    // 'rgb(117 116 116)',
    'rgb(30 133 177)',
    // 'rgb(253 152 0)',
    'rgb(94 149 61)',
    'rgb(222 77 31)',
    'rgb(255 153 37)',
    'rgb(0 141 152)',
    'rgb(183 22 97)',
    'rgb(79 45 166)',
    'rgb(46 54 77)',
  ]

  constructor() {
    ChartJS.Chart.register(
      ChartJS.DoughnutController,
      ChartJS.ArcElement,
      ChartJS.LineController,
      ChartJS.LineElement,
      ChartJS.PointElement,
      ChartJS.LinearScale,
      ChartJS.BarController,
      ChartJS.BarElement,
      ChartJS.Title,
      ChartJS.CategoryScale,
      ChartJS.Tooltip,
      ChartJS.Legend);
    moment.locale('es');

    this.legend = {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
      }
    }

    this.transitions = {
      show: {
        animations: {
          x: {
            from: 0
          },
          y: {
            from: 0
          }
        }
      },
      hide: {
        animations: {
          x: {
            to: 0
          },
          y: {
            to: 0
          }
        }
      }
    }

    this.scalesCurrency = {
      y: {
        ticks: {
          callback(value: number, index: number) {
            return `$ ${value}`;
          }
        }
      }
    }

    this.scalesBeginZero = {
      y: {
        beginAtZero: true
      }
    }

  }

  setLineChartADRRevPar(data: DataChartADRRevPar) {
    new ChartJS.Chart(
      data.nameChart,
      {
        type: 'line',
        data: {
          labels: this.createIntervalDataLabel(data.days, data.start),
          datasets: [{
            label: 'ADR',
            data: data.adr,
            backgroundColor: 'rgb(30 133 177)',
            borderColor: 'rgb(30 133 177)',
          },
          {
            label: 'RevPar',
            data: data.revpar,
            backgroundColor: 'rgb(253 152 0)',
            borderColor: 'rgb(253 152 0)',
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: true,
              text: 'ADR y RevPar',
              align: 'start',
            },
            legend: this.legend
          },
          transitions: this.transitions,
          elements: {
            point: {
              borderWidth: 0
            }
          },
          scales: this.scalesCurrency
        }
      }
    )
  }

  setLineChartOccupation(data: DataChartOccupation) {
    new ChartJS.Chart(
      data.nameChart,
      {
        type: 'line',
        data: {
          labels: this.createIntervalDataLabel(data.days, data.start),
          datasets: [{
            label: 'Ocupación',
            data: data.occupation,
            backgroundColor: 'rgb(30 133 177)',
            borderColor: 'rgb(30 133 177)',
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: true,
              text: 'Gráfico de ocupación',
              align: 'start',
            },
            legend: this.legend
          },
          transitions: this.transitions,
          elements: {
            point: {
              borderWidth: 0
            }
          },
          scales: this.scalesBeginZero
        }
      }
    )
  }

  setBarChartArrivalsOvernights(data: DataChartArrivalOvernight) {
    new ChartJS.Chart(
      data.nameChart,
      {
        type: 'bar',
        data: {
          labels: this.createIntervalDataLabel(data.days, data.start),
          datasets: [{
            label: 'Llegadas',
            data: data.arrivals,
            backgroundColor: ['rgb(30 133 177)'],
            borderColor: 'rgb(30 133 177)',
            borderWidth: 0
          },
          {
            label: 'Pernoctaciones',
            data: data.overnights,
            backgroundColor: ['rgb(253 152 0)'],
            borderColor: 'rgb(253 152 0)',
            borderWidth: 0
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: true,
              text: 'Llegadas y Pernoctaciones',
              align: 'start',
            },
            legend: this.legend
          },
          transitions: this.transitions,
          elements: {
            point: {
              borderWidth: 3
            }
          },
          scales: this.scalesBeginZero
        }
      }
    )
  }

  setDonutChartPropertyType(data: DataChartPropertyTypeDonut) {
    new ChartJS.Chart(
      data.nameChart,
      {
        type: 'doughnut',
        data: {
          labels: data.dataDonuts.map(d => (d.nombre)),
          datasets: [{
            data: data.dataDonuts.map(d => (d.valor)),
            backgroundColor: this.colors
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: true,
              text: 'Tipos de propiedades',
              align: 'start',
            },
            legend: {
              display: true,
              position: 'left',
              align: 'center',
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
              }
            }
          },
          transitions: this.transitions
        }
      }
    )
  }

  createIntervalDataLabel(days: number, start: string): string[] {
    let dataLabel: string[] = [];
    range(0, days + 1).subscribe(day => {
      dataLabel.push(
        moment(start).add(day, 'days').format('DD MMM YYYY')
      )
    });
    return dataLabel;
  }

}
