import { PropertyApiResponseData } from 'src/app/modules/core/models';
import { PropertyData } from 'src/app/modules/core/interface';

export function propertyApiDataToPropertyData(details: PropertyApiResponseData): PropertyData {
    return {

        properties: (details.propiedades).map((prop) => ({
            id: prop.id,
            name: prop.nombre,
            address: prop.direccion,
            numberRooms: prop.numero_habitaciones,
            web: prop.direccion_web,
            phone: prop.telefono,
            email: prop.email
        })),
        numberProperties: details.cantidad_alojamientos,
        numberRooms: details.cantidad_habitaciones
    }
}