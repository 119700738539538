import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { switchMap, first } from "rxjs/operators";
import { Router } from "@angular/router";

import { environment } from "src/environments/environment";
import * as moment from "moment";

import { UserService } from "./user.service";

import { LoginApi } from "../models";

@Injectable()
export class AuthService {

	set accessToken(value: string) {
		localStorage.setItem("token", value);
	}

	get accessToken(): string {
		let token = localStorage.getItem("token");
		return token ? token : '';
	}
	
	set accessUserId(value: number) {
		localStorage.setItem('userId', JSON.stringify(value))
	}
	
	get accessUserId(): number {
		let retrievedObject = localStorage.getItem("userId");
		return retrievedObject ? JSON.parse(retrievedObject) : 0;
	}

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private router: Router
	) { }

	public login(email: string, password: string) {
		return this.http.post<LoginApi>(`${environment.url_api}/signin`, { email, password })
			.pipe(
				first(),
				switchMap((apiResponse) => {
					this.accessUserId = apiResponse.id;
					this.accessToken = apiResponse.token;
					return this.userService.getUser(this.accessUserId);
				})
			);
	}

	public isAuthenticated(): boolean {
		if (this.accessToken) {
			const payload = this.getPayloadFromToken(this.accessToken);
			let hoy_unix = moment().utc().unix();

			if (
				payload && // Si se pudo extraer el payload
				payload.exp && // Si existe el tiempo de expiración
				moment.unix(payload.exp).isAfter(moment.unix(hoy_unix)) // Si el token aún no ha expirado
			) {
				// Token disponible
				return true;
			} else {
				this.logout();
				return false;
			}
		} else {
			return false;
		}
	}

	private getPayloadFromToken(token: string | null) {
		try {
			if (token) {
				var base64Url = token.split(".")[1];
				var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
				var jsonPayload = decodeURIComponent(
					atob(base64)
						.split("")
						.map(function (c) {
							return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
						})
						.join("")
				);

				return JSON.parse(jsonPayload);
			}
		} catch {
			return null;
		}
	}

	public logout() {
		localStorage.clear();
		this.router.navigate(["login"]);
	}
}
