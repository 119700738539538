import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { first, map, pluck } from "rxjs/operators";

import { environment } from "../../../../environments/environment";

import { PropertyData } from "../interface";

import { PropertyApiResponse } from "../models";

import * as Functions from "../functions"
import { UserService } from "./user.service";


@Injectable()
export class PropertyService {

	constructor(
		private http: HttpClient,
		private userService: UserService
	) { }

	getProperties(): Observable<PropertyData> {
		return this.http.get<PropertyApiResponse>(`${environment.url_api}/user/${this.userService.userId}/propiedades`)
			.pipe(
				first(),
				pluck('data'),
				map(Functions.propertyApiDataToPropertyData)
			);
	}

}
