import { User } from "./modules/core";
import { AuthService, UserService } from "./modules/core/services";

export function appInitializer(
    authService: AuthService,
    userService: UserService
) {
    return () =>
        new Promise((resolve, reject) => {
            if (authService.isAuthenticated()) {
                userService.getUser(authService.accessUserId)
                .subscribe((user: User) => {
                    userService.user = user;
                    userService.setPermisoUserRegionesComunas(user.regiones);
                })
                .add(resolve);
            } else {
                localStorage.clear();
                resolve(true);
            }
        });
}

