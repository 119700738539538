import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { first, map, pluck, switchMap, tap } from "rxjs/operators";

import { environment } from "../../../../environments/environment";

import { PermisoRegion, User, UserRegion } from "../interface";

import { UserApiResponse } from "../models";

import * as Functions from "../functions"


@Injectable()
export class UserService {
	private userSubject = new BehaviorSubject<User>({} as User);

	private permisoUserRegionesSubject = new BehaviorSubject<PermisoRegion[]>([{
		id: 0, nombre: '', comunas: [{ id: 0, nombre: '', regionId: 0 }]
	}]);

	constructor(private http: HttpClient) { }

	set user(value: User) {
		this.userSubject.next(value);
	}

	get usuarioObs(): Observable<User> {
		return this.userSubject.asObservable();
	}

	get userId(): number {
		return this.userSubject.value.id;
	}

	get paisId(): number {
		return this.userSubject.value.paisId;
	}

	set permisoUserRegiones(value: PermisoRegion[]) {
		this.permisoUserRegionesSubject.next(value);
	}

	get permisoUserRegionesObs(): Observable<PermisoRegion[]> {
		return this.permisoUserRegionesSubject.asObservable();
	}

	/**
	 * Obtiene toda la información del usuario actualmente logueado.
	 */
	getUser(userId: number): Observable<User> {
		return this.http.get<UserApiResponse>(`${environment.url_api}/users/${userId}`)
			.pipe(
				first(),
				tap(x => console.log(x)),
				pluck('data', 'user'),
				map(Functions.userApiToUser)
			);
	}

	setPermisoUserRegionesComunas(userRegiones: UserRegion[]) {
		console.log("setPermisoUserRegiones");
		this.permisoUserRegiones = userRegiones.map((userRegion: UserRegion) => ({
			id: userRegion.region.id,
			nombre: userRegion.region.nombre,
			pais_id: userRegion.region.pais_id,
			comunas: (userRegion.comunas).map(c => ({
				nombre: c.comuna.nombre,
				id: c.comuna.id,
				regionId: c.comuna.region_id
			}))
		}));
	}

}
