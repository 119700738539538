import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";

import { MatSnackBar } from "@angular/material/snack-bar";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/modules/core/services";

import { ErrorApi } from "../models";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private matSnackBar: MatSnackBar
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: ErrorApi) => {
        let msg = null;
        if(error.error.msg) {
          msg = error.error.msg;
        }

        if(error.error.message) {
          msg = error.error.message;
        }

        this.matSnackBar.open(`${msg}`, undefined, {
          duration: 3000
        });


        if (error.status === 401) {
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}
