import { UserApiResponseDataUser } from 'src/app/modules/core/models';
import { User } from 'src/app/modules/core/interface';
import { map } from 'rxjs/operators';

export function userApiToUser(
	userApi: UserApiResponseDataUser
): User {
	return {
		id: userApi.id,
		name: userApi.name,
		email: userApi.email,
		phone: userApi.phone,
		paisId: userApi.pais_id,
		regiones: (userApi.regiones).map(
            ({ id, user_hda_id, region_id, region, comunas }) => ({
                id: id,
                userHdaId: user_hda_id,
                regionId: region_id,
                region: region,
                comunas: comunas.map(c => ({
                    id: c.id,
                    regionHdaId: c.region_hda_id,
                    comunaId: c.comuna_id,
                    comuna: c.comuna
                }))
            })
        ),
	};
}
